#Contact {
    background-color: $primary;
    color: $secondary;

    #contactForm {

        h3 {
            text-align: center;
            padding-top: 60px;
            font-weight: 700;
            font-size: 2.4rem;
            color: $light;

            @include breakpoint(small only) {
                font-size: 2rem;
            }
        }

        .form {
            padding: 30px 0;

            button {
                background: $secondary;
                font-size: 1.2rem;
                align-content: center;
                font-weight: 700;
                color: $light;

                &:hover,
                &:active,
                &:focus,
                &:visited {
                    background: $primary;
                    color: $secondary;
                    border: 2px solid $secondary;
                }
            }

            .alert {
                background-color: #de2a0e !important;
                color: $light;
                margin-bottom: 30px;
            }
            
            .success {
                background-color: #00a43b !important;
                color: $light;
                margin-bottom: 30px;
            }

            @include breakpoint(small only) {
            }
        }

        input,
        textarea {
            outline: none;
            border: none;
            margin: 0;
            padding: 0;
            box-shadow: none;
            background-color: $primary;
        }
        
        textarea:focus, input:focus {
            border-color: transparent !important;
        }
        
        input:focus::-webkit-input-placeholder,
        input:focus:-moz-placeholder,
        input:focus::-moz-placeholder,
        input:focus:-ms-input-placeholder,    
        textarea:focus::-webkit-input-placeholder,
        textarea:focus:-moz-placeholder,
        textarea:focus::-moz-placeholder,
        textarea:focus:-ms-input-placeholder { 
            color:transparent; 
        }
        
        input::-webkit-input-placeholder,
        input:-moz-placeholder,
        input::-moz-placeholder,
        input:-ms-input-placeholder,    
        textarea::-webkit-input-placeholder,
        textarea:-moz-placeholder,
        textarea::-moz-placeholder,
        textarea:-ms-input-placeholder { 
            color: $secondary;
        }

        .input-block {
            width: 100%;
            position: relative;
            border-bottom: 2px solid $secondary;
            margin-bottom: 36px;
        }
        
        .input {
            display: block;
            width: 100%;
        
            font-size: 24px;
            color: $light;
            line-height: 1.2;
        }
        
        .focus-input {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
        }
        
        .focus-input::before {
        content: "";
            display: block;
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 0;
            height: 2px;
        
            -webkit-transition: all 0.4s;
            -o-transition: all 0.4s;
            -moz-transition: all 0.4s;
            transition: all 0.4s;
        
            background: $light;
        }
        
        .focus-input::after {
            content: attr(data-placeholder);
            display: block;
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0;
        
            font-size: 15px;
            color: $secondary;
            line-height: 1.2;
        
            -webkit-transition: all 0.4s;
            -o-transition: all 0.4s;
            -moz-transition: all 0.4s;
            transition: all 0.4s;
        }
        
        input.input {
            height: 48px;
        }
        
        input.input + .focus-input::after {
            top: 18px;
            left: 0;
        }
        
        textarea.input {
            min-height: 120px;
            padding-top: 12px;
            padding-bottom: 12px;
        }
        
        textarea.input + .focus-input::after {
            top: 18px;
            left: 0;
        }
        
        .input:focus + .focus-input::after,
        .has-val.input + .focus-input::after {
            top: -12px;
        }
        
        .input:focus + .focus-input::before,
        .has-val.input + .focus-input::before {
            width: 100%;
        }

        .validate-input {
            position: relative;
        }
        
        .alert-validate::before {
            content: attr(data-validate);
            position: absolute;
            max-width: 70%;
            border: 1px solid $light;
            border-radius: 2px;
            padding: 4px 15px 4px 6px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 0px;
            pointer-events: none;
        
            background-color: $primary;
            color: $light;
            font-size: 15px;
            line-height: 1.4;
            text-align: left;
        
            visibility: hidden;
            opacity: 0;
        
            -webkit-transition: opacity 0.4s;
            -o-transition: opacity 0.4s;
            -moz-transition: opacity 0.4s;
            transition: opacity 0.4s;

            @include breakpoint(small only) {
                font-size: 13px;
            }
        }
        
        .alert-validate::after {
            content: "!";
            display: block;
            position: absolute;
            color: $light;
            font-size: 16px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 8px;
        }
        
        .alert-validate:hover:before {
            visibility: visible;
            opacity: 1;
        }
        
        @media (max-width: 992px) {
            .alert-validate::before {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}