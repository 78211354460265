.logo {
    max-width: 210px;

    @include breakpoint(medium) {
        position: absolute;
        top: 30px;
        left: 30px;
    }

    @include breakpoint(small only) {
        position: absolute;
        left: 24px;
        top: 30px;
    }
}

#back-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 100;
    width: 42px;
    border: 3px solid $black;

    i {
      color: $black;
      font-size: 30px;
      margin: auto;
    }
}