footer {
    background-color: $light;

    .logo-footer {
        max-height: 210px;
        padding: 30px;
        margin-top: 9px;

        @include breakpoint(small only) {
            margin-top: 30px;
            margin-bottom: 18px;
            padding: 0;
        }
    }
    
    .cell {
        
        h3 {
            margin: 18px auto;
            padding-top: 18px;
            font-weight: 300;

            span {
                font-weight: 700;
            }

            @include breakpoint(small only) {
                font-size: 2rem;
            }
        }

        p.footer-description {
            font-size: 1.2rem;
            margin-bottom: 0;
        }

        @include breakpoint(small only) {
            margin: 0 auto !important;
        }
    }

    p { 
        margin-bottom: 6px;

        a {
            font-weight: 700;
            color: $black;
    
            &:hover,
            &:focus,
            &:active {
                color: $primary;
            }
        }
    }

    p.copyright {
        padding-bottom: 18px;

        @include breakpoint(medium only) {
            padding: 30px 0;
        }

        @include breakpoint(small only) {
            padding: 30px 0;
            margin: 0 auto;
        }
    }
}