.navButton {
    color: $black;
    width: 48px;
    height: 48px;
    border-radius: 30px;
    position: fixed;
    right: 30px;
    top: 30px;
    padding: 1px;
    font-size: 36px;
    font-weight: 900;
    cursor: pointer;
    z-index: 2;
  
    @include breakpoint(small only) {
      top: 18px;
      right: 18px;
    }
  }
  
  .overlay {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    background-color: $secondary;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .expanded {
    .overlay {
      display: block;
    }
  }
  
  .overlay-content {
    position: relative;
    top: 10%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  
    @include breakpoint(small only) {
      margin-top: 0;
      top: 0;
    }
  
    img {
      margin-bottom: 60px;
      max-width: 210px;
  
      @include breakpoint(small only) {
        max-width: 30%;
        margin: 30px auto;
      }
    }
  }
  
  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    font-weight: 700;
    color: $white;
    display: block;
    transition: 0.3s;
    color: $light;
  
    @include breakpoint(small only) {
      font-size: 24px;
    }
  }
  
  .overlay a:hover, .overlay a:focus,
  .overlay .closebtn:hover, .overlay .closebtn:focus {
    color: $primary;
  }
  
  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    color: $white;
  
    @include breakpoint(small only) {
      top: 0px;
      right: 30px;
      z-index: 1;
    }
  }