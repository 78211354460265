#Beta {
    background: $secondary;
    padding: 60px 0;

    h3 {
        font-weight: 700;
        font-size: 36px;
        color: $primary;

        @include breakpoint(small only) {
            font-size: 2rem;
        }
    }

    p {
        color: $light;
        font-size: 18px;
    }

    @include breakpoint(small only) {
        padding: 60px 1rem 60px 1rem;
    }
}