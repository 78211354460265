#KeyPoints {
    background-color: $light;

    .keyPoints {
        display: flex;
        flex-wrap: wrap;

        .keyPoints-img {
            align-self: center;
            flex: 1 0 100%;

            img {
                width: 72%;
                padding: 0 30px;
                
                @include breakpoint(medium only) {
                    width: 100%
                }
                
                @include breakpoint(small only) {
                    width: 100%;
                    margin: 30px 0;
                }
            }

            @include breakpoint(medium) {
                flex: 1 0 50%;
            }
        }

        .keyPoints-block {
            padding: 1rem;
            flex: 1 0 100%;
            align-self: stretch;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            h3 {
                font-weight: 700;
                font-size: 30px;
                color: $primary;
        
                @include breakpoint(small only) {
                    font-size: 2rem;
                }
            }
        
            p {
                font-size: 18px;
                color: $secondary;
            }

            @include breakpoint(large) {
                flex: 1 0 50%;
                padding: 4rem 0;
            }

            @include breakpoint(medium only) {
                flex: 1 0 50%;
                padding: 2rem;
            }
        }
    }

    @include breakpoint(large) {
        padding: 90px 0;
    }

    @include breakpoint(medium only) {
        padding: 60px 0;
    }

    @include breakpoint(small only) {
        padding: 60px 0 30px 0;
    }
}