#Header {
    background-color: $primary;
    
    @include breakpoint(medium only) {
        height: 480px !important;
    }

    .header {
        display: flex;
        flex-wrap: wrap;
    
        .header-block {
            padding: 1rem;
            flex: 1 0 100%;
            align-self: stretch;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            h1 {
                font-weight: 600;
                font-size: 60px;
                color: $light;

                @include breakpoint(medium only) {
                    font-size: 3rem;
                }
        
                @include breakpoint(small only) {
                    font-size: 2.4rem;
                }
            }
        
            h2 {
                font-weight: 300;
                font-size: 30px;
                color: $secondary;
                margin-bottom: 18px;
        
                @include breakpoint(medium only) {
                    font-size: 1.5rem;
                }
                
                @include breakpoint(small only) {
                    font-size: 1.2rem;
                }
            }

            a.button {
                background-color: $secondary;
                color: $light;
                font-size: 18px;
                font-weight: 600;

                &:hover,
                &:active,
                &:focus {
                    background-color: $primary;
                    color: $secondary;
                    border: 2px solid $secondary;
                }
            }

            @include breakpoint(large) {
                flex: 1 0 50%;
                padding: 2rem 0 2rem 2rem;
                margin-top: 30px;
            }
            
            @include breakpoint(medium only) {
                flex: 1 0 50%;
                padding: 90px 0 0 30px;
            }
        }

        .header-img {
            align-self: center;
            flex: 1 0 100%;
        
            img {
                width: 75%;
                z-index: 3;

                @include breakpoint(large) {
                    margin-bottom: -3%;
                    position: absolute;
                    bottom: 0;
                }
    
                @include breakpoint(medium only) {
                    margin-top: 60px;
                }
                
                @include breakpoint(small only) {
                    padding: 24px;
                    margin-top: 90px;
                }
            }

            @include breakpoint(large) {
                position: relative;
                height: 66vh;
                width: 600px;
            }

            @include breakpoint(medium) {
                flex: 1 0 50%;
            }
        }
    }
}